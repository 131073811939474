import React, { useState, useEffect } from 'react';
import './index.css';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Input, ConfigProvider, Button, message } from 'antd';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
const hostDir = process.env.REACT_APP_API_URL;
const scanDir = process.env.REACT_APP_HASH_URL

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <div>
            <button onClick={() => onPageChange(1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>First</button>
            <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;&nbsp;</button>
            <span className="text-white border border-white p-1"> Page {currentPage} of {totalPages} </span>
            <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>&nbsp;&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'text-blue-300' : 'text-white'}>Last</button>
        </div>
    );
};

const PersonalPoint = () => {

    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const [messageApi, contextHolder] = message.useMessage();

    const [account, setAccount] = useState('0x');
    const [userInfo, setUserInfo] = useState({"account":"0x", "inviter":"0x"});
    
    const [inviterUp, setInviterUp] = useState(0);
    const [circular, setCircular] = useState(0);

    const [inviteData, setInviteData] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
	const newInviter = queryParams.get('inviter');
    const [inviter, setInviter] = useState(newInviter);

    useEffect(() => {
        if (isValidAddress(account) && isValidAddress(inviter) && isValidAddress(newInviter)) {
            fetch(`${hostDir}userInfo?account=${encodeURIComponent(account)}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setUserInfo(data[0]);
                        if(data[0].inviter.length < 10) {
                            inviterSet();
                        }
                    } else {
                        inviterSet();
                    } })
                .catch(error => console.error('Error fetching data:', error));
        }
	}, [newInviter, inviter, account]);

    useEffect(() => {
		setAccount(address ? address : '0x')
	}, [address, isConnected]);

    useEffect(() => {
		fetch(`${hostDir}invitePages?inviter=${encodeURIComponent(account)}&pageSize=${encodeURIComponent(pageSize)}`)
		  .then(response => response.json())
		  .then(data => {
            if (data && data.totalPages > 0) {
                setTotalPages(data.totalPages)
            }})
		  .catch(error => console.error('Error fetching data:', error));
		fetch(`${hostDir}inviteInfos?inviter=${encodeURIComponent(account)}&page=${encodeURIComponent(page)}&pageSize=${encodeURIComponent(pageSize)}`)
		  .then(response => response.json())
		  .then(data => setInviteData(data))
		  .catch(error => console.error('Error fetching data:', error));
    }, [account, page, pageSize, userInfo]);

    useEffect(() => {
        fetch(`${hostDir}userInfo?account=${encodeURIComponent(account)}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    setUserInfo(data[0]);
                } else {
                    console.log('No data retrieved about userInfo.');
                } })
            .catch(error => console.error('Error fetching data:', error));
      }, [account]);

    // useEffect(() => {
    //     fetch(`${hostDir}invitee?inviter=${encodeURIComponent(account)}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data && data.length > 0) {
    //                 setInviteData(data);
    //             } else {
    //                 console.log('No data retrieved about userInfo.');
    //             } })
    //         .catch(error => console.error('Error fetching data:', error));
    //   }, [account]);

    useEffect(() => {
        if (inviterUp > 0) {
            if (!isValidAddress(inviter)) {
                messageApi.error('Inviter is not a valid address. Please input a new inviter.');
                console.log('Inviter is not a valid address.');
            }
            console.log('Inviter is not a valid address.');
            console.log(inviterUp);
        }}, [inviterUp]);

    useEffect(() => {
        if (circular > 0) {
            messageApi.error('Inviter is a circular address. Please input a new inviter.');
        }}, [circular]);

    // Verify the Ethereum address.
	const isValidAddress = (addr) => /^0x[0-9a-fA-F]{40}$/.test(addr);

    const inviterSet = async () => {
		if (!isConnected) {
            open()
            return false;
        }
        if (!isValidAddress(account)) {
            messageApi.error('Account is not a valid address.');
            console.log('Account is not a valid address.');
            return false;
        }
        if (account === inviter) {
            messageApi.error('Cannot set up your own account as the inviter.');
            return false;
        }
        if (!isValidAddress(inviter)) {
            setInviterUp(inviterUp + 1);
            console.log('Inviter is not a valid address.');
            console.log(inviterUp);
            return false;
        }

        fetch(`${hostDir}inviterRule?origin=${encodeURIComponent(inviter)}&compare=${encodeURIComponent(account)}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data && data.length > 0 && !data[0]) {
                    const data = {account:account, inviter:inviter};
                    fetch(hostDir + 'inviterSet', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => {
                        if (response.ok) {
                            messageApi.success('Inviter set successfully!');
                            setTimeout(() => {
                                window.location.reload();
                              }, 5000);
                        } else {
                            messageApi.error('Setting inviter error to the server.');
                        }
                    })
                    .catch(error => {
                        messageApi.error(`Network error or server is unreachable.Error: ${error}`);
                    });
                } else {
                    if(data && data.length > 0) {
                        setCircular(circular + 1);
                    } else {
                        console.log('No data retrieved about inviterRule.');
                    }
                }
            })
            .catch(error => console.error('Error fetching data:', error));
	}

    // Button click handler
    const handleClick = () => {
        const inviteLink = "https://tothemoon.market/point?inviter=" + `${account}`;
        
        // Copy link to clipboard
        navigator.clipboard.writeText(inviteLink)
        .then(() => {
            // Notify user of success
            message.success("Invite link copied to clipboard!");
        })
        .catch(() => {
            // Notify user of failure
            message.error("Failed to copy. Please try again!");
        });
    };

    return (
        <>
		{contextHolder}
        <div className="min-h-[100vh] select-none overscroll-y-auto">
            <div className='flex flex-col pt-20'>
                <div className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors  h-10 px-4 py-2 text-2xl text-slate-50 hover:font-bold hover:text-slate-50">
                    <Link to="/">[go back]</Link>
                </div>
                <ConfigProvider theme={{ components: { Form: { labelColor: '#60A5FA', itemMarginBottom: 14 }, Input: { colorBgContainer: '#2A2A3B', activeBorderColor: '#d9d9d9', hoverBorderColor: '#d9d9d9', colorText: '#d9d9d9', lineHeight: '2.5', colorTextPlaceholder: '#6b7280' }}, }}>
                    <br /><div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>Personal Point Information<br/><br/></div>
                    { account.length < 10 && <div className = "flex flex-col items-center w-full" >
                        <br />
                        <div className = "center text-2xl text-slate-50" style={{ color: '#F87171' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please connect wallet first.</div>
                    </div> }
                    { account.length > 10 && <div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;Account: &nbsp;&nbsp;&nbsp; <div className = "inline-block select-text">{account}</div></div><br />
                        <div>
                            <div className = "inline-block text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;Invite Link: </div>
                            <div className = "inline-block select-text text-slate-50" style={{ color: '#60A5FA' }}>
                                &nbsp;&nbsp;&nbsp; tothemoon.market/point?inviter={account}
                                &nbsp;&nbsp;&nbsp;<Button className= "inline-block centered-button text-xl rounded bg-green-400 text-[#181d28]" onClick={handleClick}>copy</Button>
                            </div>
                        </div>
                        {userInfo.inviter.length < 10 && <div className = "text-2xl text-slate-50 flex items-end h-full" style={{ color: '#60A5FA' }} ><br />
                            &nbsp;&nbsp;&nbsp;Inviter:  &nbsp;&nbsp;&nbsp; <Input className='w-[600px] text-[16px] font-medium' onChange={(e) => setInviter(e.target.value)} placeholder="Please input your inviter's address and set." />
                            &nbsp;&nbsp;&nbsp;<Button className= "bottom-0 text-xl py-1 p-2 text-center rounded bg-green-400 text-[#181d28]"  onClick={() => { inviterSet() }}>&nbsp;set&nbsp;</Button>
                        </div>}
                    </div> }
                    { userInfo.account.length > 10 && <div>
                        {userInfo.inviter !== '' && <div className = "select-text text-2xl text-slate-50" style={{ color: '#60A5FA' }}><br />&nbsp;&nbsp;&nbsp;Inviter: &nbsp;&nbsp;&nbsp; {userInfo.inviter}</div>}
                        <div className = "text-2xl text-slate-50 inline-block" style={{ color: '#60A5FA' }}><br />&nbsp;&nbsp;&nbsp;Total points: </div>
                        <div className = "text-2xl inline-block" style={{ color: '#FACC15' }}>&nbsp;&nbsp;&nbsp; {userInfo.private_points + userInfo.inviter_points}</div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--private points: &nbsp;&nbsp;&nbsp; {userInfo.private_points}</div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--token points: &nbsp;&nbsp;&nbsp; {userInfo.create_count > 0 ? '100' : '0'}/100 (need to have created a token)</div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--trade points: &nbsp;&nbsp;&nbsp; {userInfo.trade_count > 0 ? '100' : '0'}/100 (need to have traded once)</div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--volume points: &nbsp;&nbsp;&nbsp; {Math.floor(userInfo.trade_volume * 2000)}</div>
                        <div className = "text-2xl text-slate-50" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--inviter points: &nbsp;&nbsp;&nbsp; {userInfo.inviter_points}&nbsp;(20% of total points of invitees)</div>
                    </div> }
                    <div className='sm:px-[0px] px-[10px]'>
            {inviteData.length > 0 && <div><br/><br/>
                <div className = "text-2xl text-slate-50 inline-block" style={{ color: '#60A5FA' }}>&nbsp;&nbsp;&nbsp;Invitees' information: </div>
                <div className='w-2/5 text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg'>
                    <div className='m-1 sm:m-2 bg-[#2e303a] rounded-lg grid grid-cols-[2fr_1fr]'>
                        <div className="col-span-1 p-3 font-normal text-center">account of invitee</div>
                        <div className="col-span-1 p-3 font-normal text-center">points</div>
                    </div>
                </div>
                {inviteData.map((item, index) => (
                    <div key={index}>
                        <div className='w-2/5 text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg mt-[6px]'>
                            <div className='m-1 sm:m-2 bg-[#2e303a] rounded-lg grid grid-cols-[2fr_1fr] text-xs'>
                                <div className="col-span-1 p-3 font-normal text-center">👷
                                    <a className="hover:underline " target="_blank" rel="noopener noreferrer" href={scanDir + "address/" + item.account}>{item.account}</a>
                                </div>
                                <div className="col-span-1 p-3 font-normal text-center">
                                    {item.private_points + item.inviter_points} ➡️ {(item.private_points + item.inviter_points) * 2 / 10}
                                </div>
                            </div>
                        </div>
                    </div>))
                }
                
            </div>}
            {userInfo.account.length > 10 && userInfo.inviter_points > 0 && <div className="max-w-[800px] ml-[15%] grid gap-2 border border-transparent">
                <PaginationControls currentPage={page} totalPages={totalPages} onPageChange={setPage} />
            </div>}
        </div>
                </ConfigProvider>
            </div>
        </div>
        </>
    );
};

export default PersonalPoint;
